import JSBI from 'jsbi'

import { SolidityType } from '../constants'
import { validateSolidityTypeInstance } from '../utils'
import { ChainId } from '../constants'

/**
 * A currency is any fungible financial instrument on Ethereum, including Ether and all ERC20 tokens.
 *
 * The only instance of the base class `Currency` is Ether.
 */
export class Currency {
  public readonly decimals: number
  public readonly symbol?: string
  public readonly name?: string
  public readonly chainId?: ChainId

  /**
   * The only instance of the base class `Currency`.
   */
  public static readonly ETHER: Currency = new Currency(18, 'ETH', 'Ether', ChainId.MAINNET)
  public static readonly TESTBNB: Currency = new Currency(18, 'TBNB', 'TBNB', ChainId.BSCTEST)

  public static readonly BNB: Currency = new Currency(18, 'BNB', 'BNB', ChainId.BSC)
  public static readonly sepoliaETH: Currency = new Currency(18, 'SepETH', 'SepETH', ChainId.SEPOLIA)

  // public static readonly BNB: Currency = new Currency(18, 'BNB', 'BNB')
  // public static readonly CORE: Currency = new Currency(18, 'CORE', 'CORE')

  // public static readonly UNKNOWN_ETHER: Currency = new Currency(18, 'UNKNOWN_ETHER', 'UNETHER')

  // public static readonly ETHERes: { [chainId in ChainId]: Currency } = {
  //   [ChainId.MAINNET] : new Currency(18, 'ETH', 'Ether'),
  //   [ChainId.BSCTEST] : new Currency(18, 'BNB', 'BNB')
  // }

  /**
   * Constructs an instance of the base class `Currency`. The only instance of the base class `Currency` is `Currency.ETHER`.
   * @param decimals decimals of the currency
   * @param symbol symbol of the currency
   * @param name of the currency
   */
  protected constructor(decimals: number, symbol?: string, name?: string, chainId?: ChainId) {
    validateSolidityTypeInstance(JSBI.BigInt(decimals), SolidityType.uint8)

    this.decimals = decimals
    this.symbol = symbol
    this.name = name
    this.chainId = chainId
  }
}

// const ETHER = (chainId: ChainId) => {
//   if (chainId === ChainId.MAINNET) return Currency.ETHER
//   if (chainId === ChainId.BSCTEST) return Currency.TESTBNB

//   return Currency.UNKNOWN_ETHER
// }

const ETHER : { [chainId in ChainId] : Currency } = {
  [ChainId.MAINNET]: Currency.ETHER,
  [ChainId.BSCTEST]: Currency.TESTBNB,
  [ChainId.BSC]: Currency.BNB,
  [ChainId.SEPOLIA]: Currency.sepoliaETH
}

export { ETHER }
